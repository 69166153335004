<template>
  <div  elevation="4">
      <v-img
        contain
        src="homepage.jpg"
      ></v-img>
      <v-row no-gutters class="mt-4">
        <v-col class="mb-3" cols="12" lg="4">
          <v-card elevation="3" outlined class="mx-2" min-height="100">
            <v-img
              src="backgroundImage.jpg"
              class="black--text align-end"
              max-height="100"
              gradient="to bottom, rgba(12,12,12,.1), rgba(12,12,12,.1)"
            >
              <v-card-title class="text-center pb-1 cardHeading ">FOR EVERY MOMENT</v-card-title>
              <v-card-text>Cher offers the perfect fragrance for any occasion, birthdays, weddings, dates etc.</v-card-text>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" class="mb-3">
          <v-card elevation="3" outlined class="mx-2" min-height="100">
            <v-img
              src="backgroundImage.jpg"
              class="black--text align-end"
              max-height="100"
              gradient="to bottom, rgba(12,12,12,.1), rgba(12,12,12,.1)"
            >
            <v-card-title class="text-center cardHeading pb-1">LUXURY REDEFINED</v-card-title>
            <v-card-text>Experience high-quality fragrances inspired by premium brands, without the hefty price tag.</v-card-text>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" class="mb-2">
          <v-card elevation="3" outlined class="mx-2" min-height="100">
            <v-img
              src="backgroundImage.jpg"
              class="black--text align-end"
              max-height="100"
              gradient="to bottom, rgba(12,12,12,.1), rgba(12,12,12,.1)"
            >
            <v-card-title class="text-center cardHeading pb-1">EFFORTLESS ELEGANCE</v-card-title>
            <v-card-text>Elevate your style every day with scents that leave a lasting impression.</v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-center topSellersTitle mt-8 mb-2">
         OUR TOP SELLERS
        <v-rating
          v-model="stars"
          background-color="yellow lighten-3"
          color="yellow lighten-1"
          size="40"
        ></v-rating>
      </div>
      <v-row no-gutters>
        <v-col class="mb-2" cols="6">
          <v-card elevation="3" outlined class="mx-2 pb-4" min-height="100" color="grey lighten-2">
            <v-card-title class="text-center pb-1">MASCULINE <v-icon color="blue" class="ml-2">mdi-gender-male</v-icon></v-card-title>
            <div  class="mx-4" v-for="mTS in mensTopSellers" :key="mTS">
              {{mTS}}
            </div>
          </v-card>
        </v-col>
        <v-col class="mb-2" cols="6">
          <v-card elevation="3" outlined class="mx-2 pb-4" min-height="100" color="grey lighten-2">
            <v-card-title class="text-center pb-1">FEMININE <v-icon color="pink" class="ml-2 glow">mdi-gender-female</v-icon></v-card-title>
            <div  class="mx-4" v-for="fTS in womenTopSellers" :key="fTS">
              {{fTS}}
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="visitorCount" class="text-center fontInter mt-4 pa-1">You are our visitor number {{visitorCount}}.</div>
      <div class="text-center mt-5">
        <a href="https://www.google.com/maps/place/Cher+Luxury+Perfumes/@15.4959229,73.8235,17z/data=!4m8!3m7!1s0x3bbfc1a0c7f252cd:0xfbf683ffb9a04bb9!8m2!3d15.4959177!4d73.8260749!9m1!1b1!16s%2Fg%2F11w8qlnqj7?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">
            <v-icon class="mr-2">mdi-comment-quote</v-icon>REVIEWS FROM OUR CLIENTS.
        </a>
      </div>
      <div class="text-center mt-10">
        FOLLOW US ON INSTAGRAM FOR UPDATES
      </div> 
      <div class="flex d-flex justify-center ">
            <a  href="https://www.instagram.com/cher_goa" class="instagramLink ml-2"><v-icon color="pink" class="mr-2 mb-1" x-large>mdi-instagram</v-icon>CHER_GOA</a>
      </div>

    </div>
</template>

<script>
export default {
  props: {
    items: {type: Array},
    visitorCount: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      mensTopSellers: ["SAUVAGE", "COOL WATER", "HAWAS"],
      womenTopSellers: ["BOMSBHELL", "FLORA", "ZARA BLACK"],
      stars: 6
    };
  },
  methods: {}
};
</script>

<style>
.backgroundColor {
  background-color: rgb(243, 243, 242) !important;
}
.instagramLink {
    color: black !important; /* Adds higher priority */
    text-decoration: none !important; /* Removes underline */
    font-size: 30px;
    font-weight: 450 !important;
}
.topSellersTitle{
  font-size: 1.7rem;
  font-weight: 600;
}
.cardHeading{
  font-size: 1.4rem;
  font-weight: 700;
}
</style>